import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you</h1>
          <p>If all goes well Rupert will get your message.  If you don't hear back do please try a different way.</p>
        </div>
      </div>
    </section>
  </Layout>
)
